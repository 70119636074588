import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const pointTypesStore = defineStore('pointTypes', () => {

	function getIconImage(typeId, suffix='_w') {
		for (var i=0; i<pointTypes.length; i++) {
			if (pointTypes[i].id==typeId) return '/images/icons/' + pointTypes[i].icon + suffix + '.svg';
		}
		return '/images/icons/arrow' + suffix + '.svg';
	}

	function getType(id) {
		return pointTypes.find((point) => point.id == id);
	}
	function getTypeByName(name) {
		return pointTypes.find((point) => point.name.toLowerCase() == name.toLowerCase());
	}


	function getGliders() {
		return [63,1,2,27];
	}
	function getFreeflight() {
		return [63,6,7,17];
	}
	
	function getMarine() {
		var results = [];
		for (var i=0; i<pointTypes.length; i++) {
			if (pointTypes[i].cat=='water') results.push(pointTypes[i].id);
		}
		return results;
	}

	function getGA() {
		return [0,1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18,19,48,49,50,58];
	}


	function getCurrentFilterTypes(mode) {
		if (mode=='gliding') return getGliders();
		if (mode=='freeflight') return getFreeflight();
		if (mode=='marine') return getMarine();
		if (mode=='ga') return getGA();
		return null;
	}



	const pointTypes = [
		{id: 0,  cat: "other",	name: "Unknown", 			icon: "arrow"},
		{id: 1,  cat: "air",	name: "Glider", 			icon: "glider"},
		{id: 2,  cat: "air",	name: "Tow", 				icon: "towplane"},
		{id: 3,  cat: "air",	name: "Helicopter", 		icon: "helicopter"},
		{id: 4,  cat: "air",	name: "Parachute", 			icon: "paraglider"},
		{id: 5,  cat: "air",	name: "Drop Plane", 		icon: "plane"},
		{id: 6,  cat: "air",	name: "Hang Glider",		icon: "hangglider"},
		{id: 7,  cat: "air",	name: "Paraglider", 		icon: "paraglider"},
		{id: 8,  cat: "air",	name: "Plane", 				icon: "plane"},
		{id: 9,  cat: "air",	name: "Jet", 				icon: "jet"},
		{id: 10, cat: "air",	name: "UFO", 				icon: "ufo"},
		{id: 11, cat: "air",	name: "Balloon", 			icon: "balloon"},
		{id: 12, cat: "air",	name: "Airship", 			icon: "blimp"},
		{id: 13, cat: "air",	name: "Drone",				icon: "drone"},
		{id: 14, cat: "other",	name: "Unknown", 			icon: "arrow"},
		{id: 15, cat: "other",	name: "Static Object", 		icon: "tower"},
		{id: 16, cat: "air",	name: "Gyrocopter", 		icon: "gyro"},
		{id: 17, cat: "air",	name: "Paramotor", 			icon: "paraglider"},
		{id: 18, cat: "air",	name: "Cropduster", 		icon: "cropduster"},
		{id: 19, cat: "air",	name: "Rocket", 			icon: "rocket"},
		{id: 20, cat: "other",	name: "Person", 			icon: "person"},
		{id: 21, cat: "other",	name: "Shipping Container",	icon: "container"},
		{id: 22, cat: "ground",	name: "Car", 				icon: "arrow"},
		{id: 23, cat: "ground",	name: "Van", 				icon: "arrow"},
		{id: 24, cat: "ground",	name: "Campervan", 			icon: "arrow"},
		{id: 25, cat: "ground",	name: "Caravan", 			icon: "arrow"},
		{id: 26, cat: "ground",	name: "Trailer", 			icon: "arrow"},
		{id: 27, cat: "ground",	name: "Glider Trailer",		icon: "arrow"},
		{id: 28, cat: "ground",	name: "Bicycle", 			icon: "arrow"},
		{id: 29, cat: "ground",	name: "Scooter", 			icon: "arrow"},
		{id: 30, cat: "ground",	name: "Skateboard", 		icon: "arrow"},
		{id: 31, cat: "ground",	name: "Heavy Truck", 		icon: "arrow"},
		{id: 32, cat: "ground",	name: "Light Truck", 		icon: "arrow"},
		{id: 33, cat: "ground",	name: "Motorbike", 			icon: "arrow"},
		{id: 34, cat: "ground",	name: "Train", 				icon: "arrow"},
		{id: 35, cat: "ground",	name: "Go-Cart", 			icon: "arrow"},
		{id: 36, cat: "water",	name: "Hovercraft",			icon: "boat"},
		{id: 37, cat: "water",	name: "Yacht", 				icon: "ship"},
		{id: 38, cat: "water",	name: "Fishing Boat",		icon: "fishingboat"},
		{id: 39, cat: "water",	name: "Dinghy", 			icon: "dinghy"},
		{id: 40, cat: "water",	name: "Boat", 				icon: "boat"},
		{id: 41, cat: "water",	name: "Catamaran", 			icon: "catamaran"},
		{id: 42, cat: "water",	name: "Houseboat", 			icon: "boat"},
		{id: 43, cat: "water",	name: "Trawler", 			icon: "ship"},
		{id: 44, cat: "water",	name: "Ship", 				icon: "ship"},
		{id: 45, cat: "water",	name: "Canoe", 				icon: "canoe"},
		{id: 46, cat: "water",	name: "Kayak", 				icon: "kayak"},
		{id: 47, cat: "ground",	name: "Pickup/Ute", 		icon: "arrow"},
		{id: 48, cat: "air",	name: "Sea Plane", 			icon: "plane"},
		{id: 49, cat: "air",	name: "Amphibian", 			icon: "plane"},
		{id: 50, cat: "air",	name: "Tiltrotor", 			icon: "plane"},
		{id: 51, cat: "air",	name: "Jet Super", 			icon: "jet"},
		{id: 52, cat: "air",	name: "Jet Heavy", 			icon: "jet"},
		{id: 53, cat: "air",	name: "Jet Medium",			icon: "jet_m"},
		{id: 54, cat: "air",	name: "Jet Light", 			icon: "jet_m"},
		{id: 55, cat: "air",	name: "Turboprop Heavy",	icon: "turboprop_m"},
		{id: 56, cat: "air",	name: "Turboprop Medium",	icon: "turboprop_m"},
		{id: 57, cat: "air",	name: "Turboprop Light",	icon: "turboprop_l"},
		{id: 58, cat: "air",	name: "Plane Twin Engine",	icon: "plane"},
		{id: 59, cat: "ground",	name: "Horse",				icon: "arrow"},
		{id: 60, cat: "water",	name: "Shark",				icon: "arrow"},
		{id: 61, cat: "water",	name: "Sail Boat", 			icon: "sailboat"},
		{id: 62, cat: "water",	name: "Tanker", 			icon: "tanker"},
		{id: 63, cat: "other",	name: "Unknown Skylines", 	icon: "arrow"},
		];

	return { 
		pointTypes,
		getType,
		getTypeByName,
		getIconImage,
		getGliders,
		getFreeflight,
		getMarine,
		getGA,
		getCurrentFilterTypes
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(pointTypesStore, import.meta.hot))
}

